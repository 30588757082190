<template>
  <v-dialog v-model="editDialog" width="700">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>{{ $t('edit') }} {{ recordData.name }}</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="4">
            <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('box')" :items="boxItems" color="#757575" item-text="name" item-value="id"
              :loading="boxesLoading" v-model="financialOrderData.box" @keypress="fetchAutoCompleteBoxes($event)"
              no-filter return-object>
            </v-autocomplete>
          </v-col>
          <v-col md="4">
            <v-text-field :dark="$store.state.isDarkMode" :label="$t('user name')" readonly v-model="username"></v-text-field>
          </v-col>
          <v-col md="4">
            <v-text-field type="date" v-model="financialOrderData.date" color="#757575" :label="$t('date')" :dark="$store.state.isDarkMode">

            </v-text-field>

          </v-col>
          <v-col md="4">
            <v-text-field :dark="$store.state.isDarkMode" type="number" color="#757575" :label="$t('Value')" v-model="financialOrderData.amount"></v-text-field>
          </v-col>
          <v-col md="4">
            <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('hr.employee.check.account')" :items="accountItems" :loading="accountLoading" color="#757575"
              item-text="name" item-value="id" v-model="financialOrderData.account" no-filter
              @keypress="fetchAutoCompleteAccount($event)" return-object>
            </v-autocomplete>
          </v-col>
          <v-col md="4">
            <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('cost center')" :items="costCenterItems" color="#757575" item-text="name" item-value="id"
              :loading="costCenterLoading" v-model="financialOrderData.cost_center"
              @keypress="fetchAutoCompleteCostCenter($event)" return-object>
            </v-autocomplete>
          </v-col>
          <v-col md="8">
            <v-text-field :dark="$store.state.isDarkMode" :label="$t('explain')" v-model="financialOrderData.desc" color="#757575"></v-text-field>
          </v-col>
          <v-col md="4" class="d-flex justify-end align-center">
            <v-btn class="white--text" color="green" :loading="editBtnLoading" @click="editRecord"
              v-if="financialOrderData.action == 'pay'">تعديل أمر دقع</v-btn>
            <!-- :disabled="disableBtn" -->
            <v-btn class="white--text" color="green" @click="editRecord" :loading="editBtnLoading"
              v-if="financialOrderData.action == 'deposit'">تعديل أمر قبض</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center"> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import debounce from "../../../../helpers/debounce";
import Notifications from "../../../../helpers/toast-notifications";
export default {
  data() {
    return {
      editBtnLoading: false,
      editDialog: false,
      costCenterItems: [],
      costCenterLoading: false,
      accountItems: [],
      accountLoading: false,
      dateDialog: false,
      boxItems: [],
      boxesLoading: false,
      dialog: false,
      financialOrderData: {
        box: null,
        date: "",
        amount: null,
        account: null,
        desc: "",
        action: "",
        cost_center: null,
      },
      showIdDialog: false,
      idNumber: null,
      username: "",
    };
  },
  // computed: {
  //   disableBtn() {
  //     if (
  //       1
  //       // !this.financialOrderData.box ||
  //       // !this.financialOrderData.date ||
  //       // !this.financialOrderData.amount ||
  //       // !this.financialOrderData.account ||
  //       // !this.financialOrderData.desc ||
  //       // !this.financialOrderData.cost_center
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   },
  // },
  props: {
    recordData: Object,
  },

  methods: {
    async editRecord() {
      try {
        this.editBtnLoading = true;
        await axios.put(`/accounting/financial-order/${this.recordData.id}`, {
          box_id: this.financialOrderData.box.id,
          account_id: this.financialOrderData.account.id,
          desc: this.financialOrderData.desc,
          amount: this.financialOrderData.amount,
          action: this.financialOrderData.action,
          cost_center_id: this.financialOrderData.cost_center?.id,

          date: this.financialOrderData.date,
        });
        Notifications(
          this.$t('edit success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
      } finally {
        this.editBtnLoading = false;
      }
    },
    async fetchAutoCompleteAccount(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.accountLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.accountLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteBoxes(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.boxesLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.boxItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.boxesLoading = false;
        }
      }, 500)();
    },

    async fetchAutoCompleteCostCenter(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.costCenterLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/cost-center/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.costCenterItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.costCenterLoading = false;
        }
      }, 500)();
    },
  },
  created() {
    console.log("record data", this.recordData);
    this.financialOrderData.box = this.recordData.box;
    this.financialOrderData.date = this.recordData.date;
    this.financialOrderData.amount = this.recordData.amount;
    this.financialOrderData.account = this.recordData.account;
    this.financialOrderData.cost_center = this.recordData.cost_center;
    this.financialOrderData.desc = this.recordData.desc;
    this.financialOrderData.action = this.recordData.action;
    this.username = this.recordData.generator.username;
    this.boxItems = [this.financialOrderData.box];
    this.accountItems = [this.financialOrderData.account];
    this.costCenterItems = [this.financialOrderData.cost_center];
  },
};
</script>

<style></style>