<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <v-row>
        <v-col md="6" class="py-3 px-10" v-if="returnAbility('financial-order:index')">
          <v-autocomplete :dark="$store.state.isDarkMode" dense :label="$t('boxes')" color="#757575" :loading="boxesLoading" :items="boxes"
            item-text="name" item-value="id" v-model="filterObject.box" @change="fetchData(1)"></v-autocomplete>
        </v-col>
        <v-col md="6" class="d-flex justify-space-around">
          <app-create-financial-order v-if="returnAbility('financial-order:store')" @reFetchDataAgain="fetchData(1)"
            orderType="deposit"></app-create-financial-order>
          <app-create-financial-order v-if="returnAbility('financial-order:store')" @reFetchDataAgain="fetchData(1)"
            orderType="pay"></app-create-financial-order>
        </v-col>
      </v-row>
      <app-financial-order-filter v-if="returnAbility('financial-order:index')"
        @filterTable="fetchData(1, (filterObject = $event))"
        @resetFilter="resetFilterTable"></app-financial-order-filter>
      <app-base-table :tableOptions="tableOptions" v-if="returnAbility('financial-order:index')"
        @re-fetch-data="fetchData" @re-fetch-paginated-data="fetchData($event)"
        :serverRoute="'/accounting/financial-order'" :enableDelete="displayDeleteIcon" :flaggedItems="flaggedItemsData">
        <template slot="editFeature" slot-scope="{ row }">
          <app-edit-financial-order v-if="returnAbility('financial-order:update')"
            :recordData="row"></app-edit-financial-order>
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import createFinancialOrder from "./createFinancialOrder.vue";
import editFinancialOrder from "./editFinancialOrder.vue";
import { mapActions, mapGetters } from "vuex";
import financialOrderFilter from "./financialOrderFilter.vue";
export default {
  components: {
    appEditFinancialOrder: editFinancialOrder,
    appFinancialOrderFilter: financialOrderFilter,
    appCreateFinancialOrder: createFinancialOrder,
  },
  data() {
    return {
      boxes: [],
      boxesLoading: false,
      flaggedItemsData: ["notes", "action"],
      filterObject: {
        from: "",
        to: "",
        id: null,
        amount: null,
        action: "",
        account: null,
        desc: "",
        costCenter: null,
        done: null,
        page: 1,
        box: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      tableOptions: "accountantFinancialOrder/getTableOptions"
    }),
  },
  methods: {
    ...mapActions({
      fetchCashierFinancialOrder:
        "accountantFinancialOrder/fetchAccountantFinancialOrder",
    }),
    resetFilterTable() {
      this.filterObject.from = "";
      this.filterObject.to = "";
      this.filterObject.id = null;
      this.filterObject.amount = null;
      this.filterObject.action = "";
      this.filterObject.account = null;
      this.filterObject.desc = "";
      this.filterObject.costCenter = null;
      this.filterObject.done = null;
      this.filterObject.page = 1;
    },
    fetchData(paginationValue) {
      if (!this.returnAbility("financial-order:index")) return;
      this.fetchCashierFinancialOrder({
        paginationValue,
        tableFilter: this.filterObject,
      });
    },
  },
  async created() {
    try {
      if (this.returnAbility("financial-order:delete")) {
        this.displayDeleteIcon = true;
      }
      this.fetchData(1);
      this.boxesLoading = true;
      if (this.returnAbility("cashier-box:show")) {
        const boxes = await axios.get("/accounting/cashier-box/my-box", {
          params: {
            size: 10000,
          },
        });
        this.boxes.unshift({
          name: this.$t('all'),
          id: null,
        });
        boxes.data.data.forEach((element) => {
          this.boxes.push(element.box);
        });
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      this.boxesLoading = false;
    }
  },
};
</script>